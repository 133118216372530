import api from '../../assets/api.js';
import Vue from "vue";
import moment from 'moment';
import { EventBus } from '../../EventBus.js';

function calculateSecurityDeposit(securityDepositType, securityDepositValue , unitPrice) {
  const securityDepositTypes = {
    fixed: securityDepositValue,
    percent: ((unitPrice / 100) * securityDepositValue),
    // number refers to number of months
    number: unitPrice * securityDepositValue
  }
  
  return securityDepositTypes[securityDepositType];
}

class OnBoarding {

  constructor() {
    this.namespaced = true;

    this.state = {
      tenants: [],
      unit: {
        id: '',
        property_id: '',
        number: '',
        price: '',
        Address: {
          address: '',
          city: '',
          state: '',
          zip: '',
        }
      },
      tax_exempt: false,
      tax_exempt_description: null,
      auto_pay_required: false,
      invoices: [],
      configuration_lease:{
        id: null,
        unit_id: '',
        rent: 0,
        security_deposit: 0,
        show_security_deposit: false,
        type: '',
        start_date: '',
        end_date: '',
        bill_day: 1,
        discount_id: '',
        auto_pay_after_billing_date: 0,
        insurance_id: null,
        products: [],
        promotions: [],
        payment_cycle: 'Monthly',
        send_invoice: true, 
        invoice_send_day: 0, 
        status: '',
        notes: '',
        terms: '',
        decline_insurance: false,
        tax_exempt: false,
        tax_exempt_description: null
      },
      configuration_invoices: [],
      configuration_unit: {
        Address: {}
      },
      units_in_area: [],
      lease: {
        id: null,
        unit_id: '',
        rent: 0,
        security_deposit: 0,
        type: '',
        start_date: '',
        end_date: '',
        bill_day: 1,
        discount_id: '',
        auto_pay_after_billing_date: 0,
        promotions: [],
        payment_cycle: '',
        send_invoice: true,
        invoice_send_day: 0,
        status: '',
        notes: '',
        terms: '',
        decline_insurance: false,
        tax_exempt: false,
        tax_exempt_description: null
      },
      lead: {},
      template: {},
      insurance: {},
      isLastMonthRent: false,
      billed_months: 0,
      min_billed_months: 0,
      promotion: {},
      paymentMethods: [],
      documents: [],
      services: [],
      property: {
        id: '',
      },
      hours: {},
      contacts: [],
      contact:  {
        first: '',
        last: '',
        email: '',
        Phones:[ {
          type: 'Cell',
          phone: '',
          sms: true
        }],
        lead: {
          property_id: '',
          category_id: '',
          unit_id: ''
        }, 
        Military: {
        }
      },
      pCycleOptions:{
        label:''
      },
      discounts: {},
      promotions: {},
      payments: [],
      payment: {},
      paymentMethod: {},
      reservation: {
        reservation_id: null,
        contact_id: null,
        Lease: {},
        Setup:{}
      },
      tax_rate: 0,
      loading_name: '',
      items: [],
      // Like MOVE_IN, TRANSFERS
      onBoardingSource: "",
      lastClosedLease: null,
      latestContactData:{},
      nationalAccount :{
        keystat:{
          monthly_rent: '',
          property_count:'',
          sqm:'',
          totalBalance:'',
          unitsCount:'',
          discount:'',
          next_bill_date:'',
        },
        total_sqm:'',
        total_monthly_rent:''
      },
      processPaymentStatus: false,
      route_phone_number: {},
      follow_up:false,
      route_phone_number: {},
      lead_interaction_data: {}
    };

    this.getters = {
      route_phone_number: state => state.route_phone_number,
      lead_interaction_data: state => state.lead_interaction_data,
      contact: state => state.contact,
      pCycleOptions: state => state.pCycleOptions,
      lead: state => state.lead,
      hours: state => state.hours,
      lead: state => state.lead,
      lease: state => state.lease,
      configuration_lease: state => state.configuration_lease,
      configuration_invoices: state => state.configuration_invoices,
      configuration_unit: state => state.configuration_unit,
      invoices: state => state.invoices,
      template: state => state.template,
      unit: state => state.unit,
      tenants: state => state.tenants,
      services: state => state.services,
      insurance: state => state.insurance,
      billed_months: state => state.billed_months,
      reservation: state => state.reservation,
      property: state => state.property,
      discounts: state => state.discounts,
      promotions: state => state.promotions,
      promotion: state => state.promotion,
      payments: state => state.payments,
      payment: state => state.payment,
      paymentMethod: state => state.paymentMethod,
      taxRate: state => state.tax_rate,
      items: state => state.items,
      loadingName: state => state.loading_name,
      min_billed_months: state => state.min_billed_months,
      onBoardingSource: state => state.onBoardingSource,
      templateFees: state => {
        const unitId = Object.keys(state.template)[0];
        const template = { ... state.template[unitId] };     
        
        if(state.onBoardingSource === 'TRANSFERS' && template) {
          return template.products && 
            template.products.filter(p => p.category_type == 'transfer' && p.default_type == 'late');
        }

        return template.products;
      },
      configureObject: (state, getters) => (data) => {
        let promotions = [];
        if(getters.configuration_lease.promotions){
          promotions = getters.configuration_lease.promotions.filter(
            (p) => p.Promotion.id
          );
        }

        promotions = promotions.map((p) => {
          return {
            promotion_id: p.Promotion.id,
          };
        });

        let products = getters.services.filter(s=> s.Product.default_type === 'product' || s.Product.default_type === 'late').map(s => {
          return {
            product_id: s.Product.id,
            qty: s.qty
          }
        })

        let start_date = getters.configuration_lease.start_date ? moment(getters.configuration_lease.start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        
        let params = {
          start_date: start_date,
          end_date: getters.configuration_lease.end_date,
          discount_id: getters.configuration_lease.discount_id,
          auto_pay_after_billing_date: getters.configuration_lease.auto_pay_after_billing_date || 0,
          promotions: promotions,
          payment_cycle: getters.configuration_lease.payment_cycle,
          bill_day: parseInt(getters.configuration_lease.bill_day),
          rent: getters.configuration_lease.rent,
          security_deposit: getters.configuration_lease.security_deposit,
          coupons: [],
          
          insurance_id: getters.configuration_lease.insurance_id || getters.insurance.insurance_id,
          billed_months: getters.billed_months,
          products: getters.configuration_lease.products || products,
          reservation_id: getters.reservation.id,
          is_transfer: data.is_transfer,
          transfer_lease_id: data.transfer_lease_id,
          edit_lease: getters.configuration_lease.id ? true: false,
          id: getters.configuration_lease.id,
          send_invoice: getters.configuration_lease.send_invoice,
          tax_exempt: getters.getTaxExempt,
          tax_exempt_description: getters.getTaxExemptDescription,
          BillingCycle: getters.configuration_lease.BillingCycle,
          billing_cycle_id: getters.configuration_lease.billing_cycle_id
        }

        return params;
      },
      getLastClosedLease: state => state.lastClosedLease,
      latestContactData:(state) => state.latestContactData,
      getNationalAccount :(state) => state.nationalAccount,
      processPaymentStatus: (state) => state.processPaymentStatus,
      units_in_area: state => state.units_in_area,
      getTaxExempt: state => state.tax_exempt || false,
      getTaxExemptDescription: state => state.tax_exempt_description,
      getAutoPayRequired: state => state.auto_pay_required || false,
      getFollowUpStatus: state => state.follow_up,
    }

    this.mutations = {
      setRoutePhoneNumber: (state, payload) =>{
        state.route_phone_number = payload
      },
      setLeadInteractionData: (state, payload) =>{
        state.lead_interaction_data = payload
      },
      setNationalAccountKeyStat:(state,payload) => {
        state.nationalAccount.keystat = payload
      },
      setNationalAccount:(state,payload)=>{
        state.nationalAccount.total_sqm = payload.total_sqm
        state.nationalAccount.total_monthly_rent = payload.total_monthly_rent
      },
      setLoadingName: (state, payload) => state.loading_name = payload,
      setProperty: (state, payload) => state.property = payload,
      setPropertyHours: (state, payload) => state.hours = payload,
      setContact: (state, payload) => state.contact = payload,
      setContactLatestData: (state, payload) => state.latestContactData = payload,
      setPCycleOptions: (state, payload) => state.pCycleOptions = payload,
      setLead: (state, payload) => state.lead = payload,
      setUnit: (state, payload) => {
        state.unit = payload
      },
      setOnBoardingSource: (state, payload) => state.onBoardingSource = payload,
      setLease: (state, payload) => {
        if(!payload) return;
        state.lease = {
          id: payload.id,
          unit_id: payload.unit_id,
          rent: payload.rent,
          security_deposit: payload.security_deposit,
          status: payload.status,
          start_date: payload.start_date,
          end_date: payload.end_date,
          bill_day: parseInt(payload.bill_day),
          discount_id: payload.discount_id,
          auto_pay_after_billing_date: payload.auto_pay_after_billing_date || 0,
          promotions: payload.promotions,
          payment_cycle: payload.payment_cycle,
          BillingCycle: payload.BillingCycle,
          billing_cycle_id: payload.billing_cycle_id,
          send_invoice: !!payload.send_invoice,
          invoice_send_day: payload.invoice_send_day,
          notes: payload.notes,
          terms: payload.terms,
          decline_insurance: payload.decline_insurance,
          insurance_exp_month: payload.insurance_exp_month || null,
          insurance_exp_year:  payload.insurance_exp_year || null,
          PaymentCycleOptions: payload.PaymentCycleOptions,
          BillingCycleOptions: payload.BillingCycleOptions,
          tax_exempt: payload.tax_exempt || false,
          tax_exempt_description: payload.tax_exempt_description
        };

        if( payload.type){
          state.lease.type =  payload.type;
        } else if (payload.end_date) {
          state.lease.type = "Fixed Length";
        } else {
          state.lease.type = "Month to Month";
        };

      },
      setTaxExempt: (state, payload) => {
        state.tax_exempt = payload || false;
        if(!payload){
          state.tax_exempt_description = null
        }
      },
      setTaxExemptDescription: (state, payload) => {
       if(state.tax_exempt) {
         state.tax_exempt_description = payload
       }
      },
      setLeaseProperty: (state, payload) => {
        const { property, propertyValue } = payload;
        state.lease[property] = propertyValue
      },
      setConfigurationLeaseProperty: (state, payload) => {
        const { property, propertyValue } = payload;
        state.configuration_lease[property] = propertyValue
      },
      setConfigurationLease: (state, payload) => {
        if(!payload) return;
        state.configuration_lease = {
          id: payload.id,
          unit_id: payload.unit_id,
          rent: payload.rent,
          
          show_security_deposit: !!payload.security_deposit,
          security_deposit: payload.security_deposit,
          status: payload.status,
          start_date: payload.start_date,
          end_date: payload.end_date ? payload.end_date : null,
          bill_day: parseInt(payload.bill_day),
          discount_id: payload.discount_id,
          auto_pay_after_billing_date: payload.auto_pay_after_billing_date || 0,
          insurance_id: payload.insurance_id,
          table_rate_coverage_amount: payload.table_rate_coverage_amount,
          products: payload.products,
          promotions: payload.promotions,
          payment_cycle: payload.payment_cycle,
          BillingCycle: payload.BillingCycle,
          billing_cycle_id: payload.billing_cycle_id,
          send_invoice: payload.send_invoice == null ? true : !!payload.send_invoice,
          invoice_send_day: payload.invoice_send_day,
          notes: payload.notes,
          terms: payload.terms,
          decline_insurance: payload.decline_insurance,
          PaymentCycleOptions: payload.PaymentCycleOptions,
          BillingCycleOptions: payload.BillingCycleOptions,
          tax_exempt: payload.tax_exempt || false,
          tax_exempt_description: payload.tax_exempt ? payload.tax_exempt_description : null,
          is_free_insurance: payload.isFreeInsuranceSelected
        };
        if( payload.type){
          state.configuration_lease.type =  payload.type;
        } else if (payload.end_date) {
          state.configuration_lease.type = "Fixed Length";
        } else {
          state.configuration_lease.type = "Month to Month";
        };

      },
      setConfigurationInvoice: (state, payload) => state.configuration_invoices = payload,
      setConfigurationUnit: (state, payload) =>  state.configuration_unit = payload,
      setUnitsInArea: (state, payload) => state.units_in_area = payload,
      updateLeaseField(state, payload){
        state.lease[payload.key] = payload.value
      },
      setTenants: (state, payload) => state.tenants = payload,
      setServices: (state, payload) => state.services = payload,
      setInsurance: (state, payload) => state.insurance = payload,
      setTemplate: (state, payload) => { 
        Vue.set(state.template, payload.unit.id, payload.template);

        // state.template[payload.unit.id] = payload.template
      },
      setInvoices: (state, payload) => state.invoices = payload,
      setPromotions: (state, payload) => state.promotions[payload.unit.id] = payload.promotions,
      setDiscounts: (state, payload) => state.discounts[payload.unit.id] = payload.discounts,


      setPromotionOnLease: (state, payload) => state.promotion = payload,
      setPayment: (state, payload) => state.payment = payload,
      setPayments: (state, payload) => state.payments = payload,
      setPaymentMethod: (state, payload) => state.paymentMethod = payload,
      setReservation: (state, payload) => state.reservation = payload,
      setBilledMonths: (state, payload) =>  state.billed_months = payload,
      setMinBilledMonths: (state, payload) => {
        state.min_billed_months = payload
      },
      resetInvoice: (state, payload) => {
        state.invoices = [];
      },
      resetTenants: (state, payload) => {
        state.tenants = [];
      },
      resetContact: (state, payload) => {
        state.contact = {
          first: '',
          last: '',
          email: '',
          Phones:[ {
            type: 'Cell',
            phone: '',
            sms: true
          }],
          lead: {
            property_id: '',
            category_id: '',
            unit_id: ''
          }
        };

        state.lead = {};

      },

      resetLease: (state, payload) => {
        state.lease = {
          id: null,
          unit_id: '',
          rent: 0,
          security_deposit: 0,
          type: '',
          start_date: '',
          end_date: '',
          bill_day: '',
          discount_id: '',
          auto_pay_after_billing_date: 0,
          promotions: [],
          payment_cycle: '', 
          BillingCycle: {},
          billing_cycle_id: null, 
          send_invoice: true,
          invoice_send_day: 0,
          notes: '',
          status: null,
          terms: '',
          decline_insurance: 0,
          tax_exempt: false,
          tax_exempt_description: null
        }
        state.template = {};
        state.insurance = {};
        state.isLastMonthRent = false;
        state.billed_months = 0;
        state.promotion = {};
        state.paymentMethods = [];
        state.documents = [];
        state.services = [];
        state.hours = {};
        state.contacts = [];

        state.discounts = {};
        state.promotions = {};
        state.payments = [];
        state.payment = {};
        state.paymentMethod = {};
        state.tax_rate = 0;
        state.min_billed_months = 0;
      },

      resetConfigurationLease: (state, payload) => {
        state.configuration_lease = {
          id: null,
          unit_id: '',
          rent: 0,
          show_security_deposit: false,
          security_deposit: 0,
          type: '',
          start_date: '',
          end_date: '',
          bill_day: 1,
          discount_id: '',
          auto_pay_after_billing_date: 0,
          promotions: [],
          payment_cycle: '',
          BillingCycle: {},
          billing_cycle_id: null,
          products: [],
          send_invoice: true,
          invoice_send_day: 0,
          status: '',
          notes: '',
          terms: '',
          decline_insurance: false,
          tax_exempt: false,
          tax_exempt_description: null
        }
      },
      resetConfigurationInvoice: (state, payload) => {
        state.configuration_invoices = {}
      },
      resetConfigurationUnit: (state, payload) => {
        state.configuration_unit = {
          Address: {}
        }
      },
      resetUnitsInArea: (state) => {
        state.units_in_area = [];
      },
      resetReservation: (state) => {
        state.reservation = {
          reservation_id: null,
          contact_id: null,
          Lease: {},
          Setup: {}
        };
      },
      resetProperty(state){
        state.property = {
          id: '',
        }
      },
      resetUnit(state){
        state.unit = {
          property_id: '',
          number: '',
          price: '',
          Address: {
            address: '',
            city: '',
            state: '',
            zip: '',
          }
        }
      },
      resetInitialCharges(state){
        state.invoices = [];
      },
      setTaxRate(state,payload){
        state.tax_rate = payload;
      },

      setChecklistItems(state,payload){
        // This is a temporary fix until the socket issue is resolved
        let documents_initiated = state.items.filter(si => si.status == 'initiated');
        if(documents_initiated && documents_initiated.length){
          for(let i = 0; i < documents_initiated.length; i++){
            let index = payload.findIndex(p => p.id === documents_initiated[i].id);
            if(index >= 0 && payload[index].status == 'ready'){
              payload[index].status = 'initiated';
            }
          }
        }
        state.items = payload;
      },

      setChecklistItemStatus(state,payload){
        state.items[payload.index].status = payload.status;
      },

      updateChecklistItem: (state,payload) => {

        let index = state.items.findIndex(item => item.id === payload.checklist_id);

        if(index < 0) {
          index = state.items.findIndex(item => item.Upload && item.Upload.id === payload.Upload.id);
        }

        if(index < 0) return;

        Vue.set(state.items[index], 'completed', payload.completed);
        Vue.set(state.items[index], 'status', payload.status);
        Vue.set(state.items[index], 'Upload', payload.Upload);
        if(payload.Upload){
          Vue.set(state.items[index], 'upload_id', payload.Upload.id);
        }



      },


      resetChecklistItemUpload: (state, payload) => {
        state.items[payload.index].Upload = {};
        state.items[payload.index].completed = false ;
        state.items[payload.index].upload_id = null ;
      },

      removeExtraOnboardingInvoices: (state, payload) => {
        const { invoices } = payload;
        state.invoices = state.invoices.filter(obj1 => invoices.some(obj2 => obj1.lease_id === obj2.lease_id && obj1.period_start === obj2.period_start && obj1.period_end === obj2.period_end));
      },

      removeExtraConfigurationInvoices: (state, payload) => {
        const { invoices } = payload;
        state.configuration_invoices = state.configuration_invoices.filter(obj1 => invoices.some(obj2 => obj1.lease_id === obj2.lease_id && obj1.period_start === obj2.period_start && obj1.period_end === obj2.period_end));
      },
      setLeadId:(state, payload)=>{
        state.lead.id = payload.lead_id
      },
      setAutoPayRequired:(state, payload)=>{
        state.auto_pay_required = payload;
      },
      changeProcessPaymentStatus: (state, payload) => {
        state.processPaymentStatus = payload;
      },

      setLastClosedLease(state,payload){
        state.lastClosedLease = payload;
      },
      setFollowUpStatus: (state,payload)=>{
        state.follow_up = payload
      },

    }

    this.actions = {

      addRoutePhoneNumber({ commit }, phoneNumber) {
        commit('setRoutePhoneNumber', phoneNumber);
      },
      addLeadInteractionData({ commit }, leadInteractionData){
        commit('setLeadInteractionData', leadInteractionData)
      },
      async getNationalAccountKeystat({getters, commit},data){
        let url = api.KEY_STATISTICS + `?contact_id=${data.contact_id}`;
        if (data.property_id != 'all-property') {
          url += `&property_id=${data.property_id}`;
        }
        try {
          let r = await api.get(this, url);
          if(data.property_id == 'all-property') {
            let data ={
              total_sqm:String(r.sqm),
              total_monthly_rent:r.monthly_rent
            }
            commit('setNationalAccount',data)
          }
          commit('setNationalAccountKeyStat',r)
        } catch (error) {
          console.error('Error fetching key statistics:', error);
        }
      },

      async getHours({getters, commit}, data){
        if(!getters.property.id || getters.property.id !==  data.property_id){
          let r = await api.get(this.$app, api.PROPERTIES +  data.property_id + '/hours');
          commit('setPropertyHours', r.hours);
        }
      },

      async getPropertyInfo({getters, commit, dispatch}, data){
        // Data is a property object

        commit('setProperty', data);
        await dispatch('getHours', { property_id: data.id});


      },

      async setAutoPayRequiredForRental({ commit }, data){
        const { selected_promotions = [], available_promotions = [] } = data;
        if(!available_promotions.length) return;
        const condition = selected_promotions?.length ? selected_promotions.filter(p => available_promotions.some(ap => p.Promotion.id === ap.id && ap.conditions?.includes("require_autopay"))).length : 0;
        commit('setAutoPayRequired', !!condition);
      },
      async saveLead({state, commit, dispatch}, payload){

        let context = payload?.context ? payload.context : 'general';
        let r = {};
        if(context === 'lead_only'){
          r = await api.put(this.$app, api.LEADS + payload.lead_id,  payload.lead);
          return r.contact_id
        }

        let contact_id = payload.contact_id;
        let lead = payload.lead;
        
        if(contact_id){
          r = await api.put(this.$app, api.CONTACTS + contact_id + "?scope=general" ,  payload.contact);
          if(payload.lead_id){
            r = await api.put(this.$app, api.CONTACTS + contact_id + '/lead/' + payload.lead_id,  payload.lead);
          } else {
            payload.contact = {...payload.contact, id: contact_id}
            lead.Contact =  payload.contact;
            if(payload.contact.rent_as_business){
              r = await api.post(this.$app, api.LEADS + "?scope=contacts", lead);
            }else{
              r = await api.post(this.$app, api.LEADS, lead);
            }
          
          }
          
          commit('setLeadId',r) // setting lead id to store of current saved lead.
          await dispatch('getContactInfo', {contact_id: contact_id, force: true,invocation_source:'SAVE_LEAD'});
        } else {
          lead.Contact =  payload.contact;
          if(payload.contact.rent_as_business){
            r = await api.post(this.$app, api.LEADS + "?scope=contacts", lead);
          }else{
            r = await api.post(this.$app, api.LEADS, lead);
          }
          commit('setLeadId',r) // setting lead id to store of current saved lead.
          await dispatch('getContactInfo', {contact_id: r.contact_id});
          contact_id = r.contact_id;
        }

        EventBus.$emit('lead_saved');
        return contact_id;
      },


      async updateContact({getters, commit, state, dispatch}, data){
        let copiedData = { ...data };
        let r
        if (copiedData.hasOwnProperty('Leases')) {
            delete copiedData.Leases;
        }
        if (copiedData.hasOwnProperty('Representative')) {
            delete copiedData.Representative;
        }

        if (copiedData?.dob?.includes('*')) {
          delete copiedData.dob;
        }

        if (copiedData?.ssn?.includes('*')) {
          delete copiedData.ssn;
        }

        if (copiedData?.driver_license?.includes('*')) {
          delete copiedData.driver_license;
        }
        // added to show notification when error with update contact occurs
        try {
           r = await api.put(this.$app, api.CONTACTS + data.id + "?scope=contacts&access=false", copiedData);
        } catch (error) {
          await dispatch('globalNotificationsStore/setGlobalNotification', {
            description: error
          }, { root: true });
          throw new Error(error);
        }
    
        if(r.military_id){
          data.Military.id = r.military_id
        }
        if(state.contact?.status){
          data.status = state.contact?.status;
        }
        commit('setContact',  data);
      },

      async getContactInfo({getters, commit, state}, data){
        if(data.force || (!getters.contact.id || getters.contact.id !== data.contact_id)){
          let clickedEvent = data.click_event ? '/?event='+data.click_event+'' : '';

          let response = await api.get(this.$app, api.CONTACTS +  data.contact_id + clickedEvent);
          if(this.getters['authenticationStore/rolePermission']('view_tenant_sensitive_info')){
            let unmaskedData = await api.get(this.$app, api.CONTACTS +  data.contact_id + '/sensitive-information');
            response.contact = {...response.contact, dob: unmaskedData.contact.dob, ssn: unmaskedData.contact.ssn, driver_license: unmaskedData.contact.driver_license}
          }
          let lead_response
          if(!response.contact.Phones.length){
            response.contact.Phones.push({
              type: 'Cell',
              phone: '',
              sms: true,
            })
          }
          if(!response.contact.Representative || 
            (Array.isArray(response.contact.Representative) && response.contact.Representative.length === 0) ||
            (typeof response.contact.Representative === 'object' && Object.keys(response.contact.Representative).length === 0)){
            response.contact.Representative = {
              id: null,
              type: '',
              is_emergency: false,
              is_cosigner: false,
              is_military: false,
              is_authorized: false,
              is_lien_holder: false,
              lease_id: '',
              Contact: {
                  id: null,
                  first: '',
                  last: '',
                  email: '',
                  Phones: [{
                      id: null,
                      type: 'Cell',
                      code: '1',
                      number: '',
                      phone: '',
                      sms: false
                  }],
                  Addresses: [
                      {
                          id: null,
                          type: 'Home',
                          address_id: '',
                          Address:{
                              address: '',
                              address2: '',
                              city: '',
                              neighborhood: '',
                              lat: '',
                              lng: '',
                              state: '',
                              zip: ''
                          }
                      }
                  ],
              }
            }
          }
          commit('setContact',  response.contact);
          // first check if lead is set in store, second if lead is not set what action should be taken to set lead
          // why are we setting lead here?? after getting a contact info.
          if(data.click_event !== 'useThisContact' && state.lead?.id){
            lead_response = await api.get(this.$app, api.LEADS + state.lead?.id );
            commit('setLead',  lead_response.lead);
          }else{
            if(state?.lead?.id){
              lead_response = await api.get(this.$app, api.LEADS + state.lead?.id );
              commit('setLead',  lead_response.lead);
            }else
              // if lead id is not there and called from save lead set the latest lead data
              if(data.invocation_source === "SAVE_LEAD") {
                commit('setLead',  response.contact.Leads[response.contact.Leads.length-1]); 
              }
          }
        }
      },

      async getUnitInfo({getters, commit}, data){
        if(!getters.unit.id || getters.unit.id !== data.unit_id){
          let response = await api.get(this.$app, api.UNITS +  data.unit_id);
          commit('setUnit',  response.unit);
        }
      },


      async getConfigurationUnitInfo({getters, commit}, data){
        if(!getters.configuration_unit.id || getters.configuration_unit.id !== data.unit_id){
          let response = await api.get(this.$app, api.UNITS +  data.unit_id);
          commit('setConfigurationUnit',  response.unit);
        }
      },

      async getUnitsInArea({getters, commit}, data){
        let refreshUnits = !getters.units_in_area.length || (data.current_unit_number && getters.units_in_area[0] !== data.current_unit_number);
        if(refreshUnits && data.area_id && getters.contact.id) {
          let r = await api.get(this.$app, api.CONTACTS + getters.contact.id + '/access');
          if (r.access && r.access.length) {
              let unitsInAreaList = [];
              let accessList = r.access;
              let accessUnits = accessList.flatMap((element) => {
                  let unitNumber = [];
                  if (element.area_id == data.area_id) {
                      unitNumber = element.unit_number;
                  };
                  return unitNumber;          
              });
              unitsInAreaList.push(...accessUnits);
              commit('setUnitsInArea', unitsInAreaList);
          }
        }
      },

      async getPending({getters, commit, dispatch}, data) {
        let r = await api.get(this.$app, api.UNITS + data.unit_id + '/pending');
        let lease_discounts = await api.get(this.$app, api.LEASES + r.lease.id + '/discounts');

        let promotions_arr = [];
        let discounts_arr = [];

        if(lease_discounts.discounts && lease_discounts.discounts.length){
          promotions_arr = lease_discounts.discounts.filter(p =>{
            if(p.Promotion.label === "promotion") return p;
          });
          discounts_arr = lease_discounts.discounts.filter(p =>{
            if(p.Promotion.label === "discount") return p;
          });
        }

        if (r.lease) {
          r.lease.status = 2;
          r.lease.start_date = moment(r.lease.start_date, 'YYYY-MM-DD').startOf('day').valueOf();
          r.lease.end_date = r.lease.end_date ? moment(r.lease.end_date, 'YYYY-MM-DD').startOf('day').valueOf() : null;
          r.lease.unit_id = data.unit_id;
          r.lease.payment_cycle = r.lease.payment_cycle;
          r.lease.promotions = promotions_arr || [];
          r.lease.discount_id = (discounts_arr && discounts_arr.length && discounts_arr[0].Promotion.id) || '';
          r.lease.billing_cycle_id = r.lease?.BillingCycleOptions.find(bco => r?.lease?.BillingCycle?.billing_cycle_id === bco.billing_cycle_id)?.billing_cycle_id;

          await dispatch('getUnitInfo', { unit_id: data.unit_id });
          await dispatch('getTemplate');
          await commit('setLease', r.lease);
          await dispatch('getTenants');
          await dispatch('getServices');
          await dispatch('getEstimate');
          await dispatch('getPromotions');
          //do the discount fetching part and then do the other move in part from lead follow up whch is gotoleadintake

          if(getters.tenants.length){
            let contact = getters.tenants[0].Contact;
            await dispatch('getContactInfo', {contact_id : contact.id});
          }

        }

      },


      /* this should:
        Set reservation
        Set Lease info in reservation.Lease   - change basic lease info, incl. promotion
        set unit in reservation.Unit          - change unit on the reservation
        set active contact info
        set Services in reservation.Services (this is reservation services)
        set Template in reservation.Template
      */

      async getReservation({getters, commit, dispatch}, data) {

        if (data.reservation.Lease) {
          data.reservation.Lease.start_date = moment(data.reservation.Lease.start_date).format('YYYY-MM-DD')
          data.reservation.Lease.end_date =  data.reservation.Lease.end_date ? moment(data.reservation.Lease.end_date).format('YYYY-MM-DD') : null;
          data.reservation.Lease.billing_cycle_id =  data.reservation.Lease?.BillingCycleOptions?.find( bco => bco.billing_cycle_id === data.reservation.Lease?.BillingCycle?.billing_cycle_id)?.billing_cycle_id;
          data.reservation.Lease.status = 0;

          let unit_response = await api.get(this.$app, api.UNITS +  data.reservation.Lease.unit_id);
          data.reservation.Unit = unit_response.unit;


          // TODO, what happens if there is no template set?
          let template_response = await api.get(this.$app, api.PROPERTIES + data.reservation.Unit.property_id + '/templates?unit_type_id='+data.reservation.Unit.unit_type_id);
          data.reservation.Template = template_response.Template;

          // TODO do we need this?
          let services_response = await api.get(this.$app, api.LEASES + data.reservation.Lease.id + '/services');
          data.reservation.Services = services_response.services;

          //get lease-set-up
          // let setup = await api.get(Vue, api.UNITS + data.reservation.Lease.unit_id + '/lease-set-up', {
          //   reservation_id: data.reservation.id
          // });
          // data.reservation.Setup = setup.details;

          commit('setUnit', unit_response.unit);
          commit('setReservation', data.reservation);
          dispatch('setReservationLease');

        }
        await dispatch('getContactInfo', { contact_id : data.contact_id});
      },

      async getPromotions({getters, commit}, data){
        let unit = data || getters.unit;
        if(getters.promotion[unit.id] && getters.promotion[unit.id].length) return;
        let r = await api.get(this.$app, api.UNITS + unit.id + '/promotions');

        commit('setPromotions',{
          unit: unit,
          promotions: r.promotions.filter( p => p.label === "promotion")
        });

        commit('setDiscounts',{
          unit: unit,
          discounts: r.promotions.filter( p => p.label === "discount")
        });
      },


      async getTenants({getters, commit}){
        let r = await api.get(this.$app, api.LEASES + getters.lease.id + '/tenants');
        await commit('setTenants', r.tenants);
      },

      async getServices({getters, commit}){
        commit('changeProcessPaymentStatus', true)
        let r = await api.get(this.$app, api.LEASES + getters.lease.id + '/services');
        // Because we can only have one insurance product on a lease currently

        if(r.insurance.length){
          commit('setInsurance', r.insurance[0]);
        }

        if(r.services.length) {
          commit('setServices', r.services);
        }
        commit('changeProcessPaymentStatus', false)
      },

      async getTemplate({getters, commit}, data) {
        
        let unit; 
        let refetchTemplate = false;
        
        if(data && data.configuration_unit){
          unit = data?.configuration_unit;
          refetchTemplate= data?.refetchTemplate || false;
        }
        else if(!data){
          unit = getters.unit
          data={isNational: false}
        }
        else{
          unit = data;
          refetchTemplate= data?.refetchTemplate;
          data={isNational: false};
        }

        if(getters.template[unit.id] && !refetchTemplate) return;
        
        // let r = await api.get(this.$app, api.PROPERTIES + unit.property_id + '/templates?national_accounts=true')
        let url = api.PROPERTIES + unit.property_id + '/templates?unit_type_id='+unit?.unit_type_id;
        if (data.isNational) {
            url += '&national_accounts=true';  // Add your query parameter here
        }

        let r = await api.get(this.$app, url);
        
        if(!r.Template) return;
        var template = r.Template;


        commit('setTemplate', {
          unit: unit,
          template: template
        });
      },

      async buildDefaultLease({getters, commit, dispatch}, data){
        if(!getters.unit.id && !data) return;

        await dispatch('getTemplate', data);
        if(getters.contact?.id){
          await dispatch('billingProfileStore/getBillingProfiles', {contact: getters.contact}, {root: true});
        }

        // if(getters.lease.id) {
        //   //await dispatch('getPending');
        // } else {
        await dispatch('setLeaseDefaults', data);

        // }
      },


      async setLease({getters, commit}, data){
        commit('setLease', data);
      },



      async setLeaseDefaults({getters, commit, dispatch}, data){

        let unit 

        if(data && data.configuration_unit){
          unit = data?.configuration_unit;
        }
        else if(!data){
          unit = getters.unit
        }
        else{
          unit = data;
        }


        let lease  = {
            rent: unit.price,
            unit_id: unit.id,
            security_deposit: getters.template[unit.id]?.security_deposit_type ? calculateSecurityDeposit(
            getters.template[unit.id]?.security_deposit_type,
            getters.template[unit.id]?.deposit_amount,
            unit.price
          ) : null,
          show_security_deposit: !!getters.template[unit.id]?.security_deposit_type,
          start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
          bill_day: getters.template[unit.id]?.bill_day.toLowerCase() === '1st of the month' ? 1 : moment().format('D'),
          send_invoice: !!getters.template[unit.id]?.email_statement,
          invoice_send_day: getters.template[unit.id]?.invoiceSendDay,
          notes: '',
          promotions: [],
          payment_cycle: null,
          BillingCycle: null,
          billing_cycle_id: null,
          discount_id: '',
          auto_pay_after_billing_date: getters.template[unit.id]?.auto_pay_after_billing_date || 0,
          tax_exempt: getters.template[unit.id]?.getTaxExempt || false,
          tax_exempt_description: getters.template[unit.id]?.getTaxExemptDescription
        }

        let profile = await this.getters['billingProfileStore/currentBillingProfile'](getters.contact?.id, unit.property_id);

        if(profile){
          lease.bill_day = profile.bill_day;
          lease.send_invoice = profile.send_invoice;
          lease.invoice_send_day = profile.invoice_send_day;
          lease.auto_pay_after_billing_date = profile.auto_pay_after_billing_date;
          if(profile.tax_exempt){
            lease.tax_exempt = profile.tax_exempt;
            lease.tax_exempt_description = lease.tax_exempt ? profile.tax_exempt_description : null;
          }
          let payment_cycle_options = getters.template[unit.id]?.payment_cycle_options.map(pco => pco.label) || [];
          if(payment_cycle_options?.includes(profile?.payment_cycle)){
            lease.payment_cycle = profile.payment_cycle;
          }

          let billing_cycle_options = getters.template[unit.id]?.BillingCycleOptions;

          if(!payment_cycle_options?.length && billing_cycle_options?.length){
            let billing_cycle_id = billing_cycle_options.find( bco => bco.billing_cycle_id === profile.billing_cycle_id)?.billing_cycle_id;
            if(billing_cycle_id){
              lease.billing_cycle_id = billing_cycle_id;
            }
          }
        }

        if(getters.template[unit.id]?.lease_duration && getters.template[unit.id]?.lease_duration_type){
          lease.end_date = moment().add(getters.template[unit.id].lease_duration, getters.template[unit.id].lease_duration_type).subtract(1, 'day').format('YYYY-MM-DD HH:mm')
        }

        if(getters.template[unit.id]?.lease_type){
          lease.type = getters.template[unit.id].lease_type;
        } else if (unit.type === 'residential'){
          lease.type = 'Fixed Length';
        } else {
          lease.type = 'Month to Month';
        }

        commit('setConfigurationLease', lease);
        //commit('setLease', lease);
      },

      setReservationLease({getters, commit, dispatch}, data){

        let lease  = {
          id: getters.reservation.Lease.id,
          rent: getters.reservation.Lease.rent,
          unit_id: getters.unit.id,
          show_security_deposit: !!getters.reservation.Lease.security_deposit,
          security_deposit: getters.reservation.Lease.security_deposit,
          status: 0,
          start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
          bill_day:  getters.reservation.Lease.bill_day,
          send_invoice: !!getters.reservation.Lease.send_invoice,
          invoice_send_day: getters.reservation.Lease.invoice_send_day,
          notes: getters.reservation.Lease.notes,
          promotions: getters.reservation.Lease.promotions,
          payment_cycle: 'Monthly',
          BillingCycle: getters.reservation.Lease?.BillingCycle,
          BillingCycleOptions: getters.reservation.Lease?.BillingCycleOptions,
          billing_cycle_id: getters.reservation.Lease?.billing_cycle_id,
          discount_id: getters.reservation.Lease.discount_id,
          auto_pay_after_billing_date: getters.reservation.Lease.auto_pay_after_billing_date || 0
        };

        if(getters.template.lease_duration && getters.template.lease_duration_type){
          lease.end_date = moment().add(getters.reservation.Template.lease_duration, getters.reservation.Template.lease_duration_type).subtract(1, 'day').format('YYYY-MM-DD HH:mm')
        };

        if(getters.template.lease_type){
          lease.type = getters.reservation.Template.lease_type;
        } else if (getters.unit.type === 'residential'){
          lease.type = 'Fixed Length';
        } else {
          lease.type = 'Month to Month';
        }
        commit('setLease', lease);
      },



      copyLeaseFields({getters, commit, dispatch}, data){
        // if(!getters.reservation.id) return;

        let lease = JSON.parse(JSON.stringify(getters.lease));
        if( getters.reservation.id){
          lease.notes = getters.reservation.Lease.notes;
          lease.promotions = getters.reservation.Lease.promotions;
          
          lease.discount_id = getters.reservation.Lease.discount_id;
          lease.auto_pay_after_billing_date = getters.reservation.Lease.auto_pay_after_billing_date || 0;
          lease.start_date = getters.reservation.Lease.start_date;
          lease.end_date = getters.reservation.Lease.end_date;
        }
        commit('setConfigurationLease', lease);
      },

      async convertReservation({getters, commit, dispatch}, lease) {
        if(getters.reservation.id){
          //commit('setLease', lease);
          lease.reservation_id = getters.reservation.id;
          lease.save = true;
          lease.type = 'lease';
          let r = await api.post(this.$app, api.UNITS +  lease.unit_id + '/configure', lease);
          // let r = await api.put(Vue, api.RESERVATIONS + getters.reservation.id + '/convert-to-lease', lease);
          // lease.id = r.lease_id;
          lease.status = 2;
          lease.BillingCycleOptions = r.lease?.BillingCycleOptions;
          lease.BillingCycle = r.lease?.BillingCycle;
          await commit('setLease', lease);
          // await dispatch('getServices');
          // await dispatch('getEstimate');

        }
      },



      async updateLease({getters, commit}, lease) {
        if(!getters.lease.id) return;
        let data = {
          bill_day: lease.bill_day,
          end_date: lease.end_date,
          notes: lease.notes,
          promotions: lease.promotions,
          payment_cycle: lease.payment_cycle,
          BillingCycle: lease.BillingCycle,
          billing_cycle_id: lease.billing_cycle_id,
          discount_id: lease.discount_id,
          auto_pay_after_billing_date: lease.auto_pay_after_billing_date || 0,
          rent: lease.rent,
          security_deposit: 1,
          send_invoice: !!lease.send_invoice,
          invoice_send_day: lease.invoice_send_day,
          start_date: lease.start_date,
          unit_id:  lease.unit_id,
          tax_exempt: lease.tax_exempt || false,
          tax_exempt_description: lease.tax_exempt_description
        }

        let r = await api.put(this.$app, api.LEASES + getters.lease.id, data);
        commit('setLease', lease);
      },

      async saveLease({getters, commit, dispatch}, data) {
        let lease = {
          bill_day: data.bill_day,
          end_date: data.end_date,
          notes: data.notes,
          promotions: data.promotions,
          payment_cycle: data.payment_cycle,
          BillingCycle: data.BillingCycle,
          billing_cycle_id: data.billing_cycle_id,
          discount_id: data.discount_id,
          auto_pay_after_billing_date: data.auto_pay_after_billing_date || 0,
          rent: data.rent,
          security_deposit: data.security_deposit,
          send_invoice: !!data.send_invoice,
          invoice_send_day: data.invoice_send_day,
          start_date: data.start_date,
          unit_id:  data.unit_id,
          type: 'lease',
          save: true,
          edit_lease: getters.configuration_lease.id ? true: false,
          id: getters.configuration_lease.id,
          products: data.products,
          insurance_id: data.insurance_id,
          contact_id: getters?.contact?.id,
          tax_exempt: data.tax_exempt || false,
          tax_exempt_description: data.tax_exempt_description,
          table_rate_coverage_amount: data.table_rate_coverage_amount || getters.lease?.table_rate_coverage_amount || null,
        }

        if (data.national_accounts) {
          // Added national_accounts key to lease object and set it to true
          lease.contact_id = data.contact_id;
          lease.national_accounts = true;
      }
      try {
        let r = await api.post(this.$app, api.UNITS +  data.unit_id + '/configure', lease);
        // let r = await api.post(Vue, api.LEASES, lease);

        lease.id = r.lease.id;
        lease.status = 2; // set pending status
        lease.PaymentCycleOptions = data.PaymentCycleOptions; // set pending status
        lease.BillingCycleOptions = r.lease?.BillingCycleOptions;
        lease.BillingCycle = r.lease?.BillingCycle;

        commit('setLease', lease);
        return lease;
      } catch (error) {
        dispatch('globalNotificationsStore/setGlobalNotification', {
          description: error 
        }, { root: true });
        throw new Error(error);

        // return error;
      }
      },

      async saveTenants({getters, commit, dispatch}, lease) {
        if(!getters.contact.id) return false;
        let r = await api.post(this.$app, api.LEASES + getters.lease.id + '/tenants', {
          contact_id: getters.contact.id,
          lead_id:lease.lead_id
        });

        return r.lease_id;

      },

      async deleteReservation({getters, commit, dispatch}, data) {
        let r = await api.delete(this.$app, api.RESERVATIONS + getters.reservation.id);
        commit('resetReservation');
      },

      async declineInsurance({getters, commit, dispatch}, data) {
        await api.put(this.$app, api.LEASES + getters.lease.id + '/decline-insurance/', {
          ...data
        });
        commit('updateLeaseField', { key: 'decline_insurance', value: data });
      },

      async saveInsurance({getters, commit, dispatch}, data) {
        await api.post(this.$app, api.LEASES + getters.lease.id + '/template-insurance/', data);
        await dispatch('getEstimate',{generateInvoices: true});
      },

      async saveService({getters, commit, dispatch}, data) {
        let r = await api.post(this.$app, api.LEASES + getters.lease.id + '/services', data);
        await dispatch('getServices');
        await dispatch('getEstimate', {updateInvoice: true, generateInvoices: true});
      },

      async updateLeaseTaxExemptHistory({getters, commit, dispatch}, data) {
        if(!this.getters['authenticationStore/rolePermission']('no_tax_rent')) return;
        const {tax_exempt_description = null, skip_history = false, tax_exempt = false } = data;
        try {
          let r = await api.patch(this.$app, api.LEASES + data.lease_id + '/update-tax-exempt', {
            tax_exempt: tax_exempt || false,
            tax_exempt_description: tax_exempt ? tax_exempt_description : null,
            skip_history
          });
          return r;
        } catch (err) {
          dispatch('globalNotificationsStore/setGlobalNotification', {
            description: err 
          }, { root: true });
          throw new Error(err);
        }
      },

      async updateService({getters, commit, dispatch}, data) {
        let r = await api.put(this.$app, api.LEASES +  getters.lease.id  + '/services/' + data.service_id, data.data)
        await dispatch('getServices');
        await dispatch('getEstimate', {updateInvoice: true, generateInvoices: true});
      },

      async deleteService({getters, commit, dispatch}, data) {
        let r = await api.delete(this.$app, api.SERVICES, data.service_id);
        await dispatch('getServices');
        await dispatch('getEstimate', {updateInvoice: true, generateInvoices: true});
      },

      async deleteInsurance({getters, commit, dispatch}, data) {
        await api.delete(this.$app, api.LEASES + getters.lease.id + '/template-insurance/');
        commit('setInsurance', {});
        await dispatch('getEstimate', {updateInvoice: true, generateInvoices: true});

      },

      async payInvoice({getters, commit, dispatch}, data = {}){

        let r = await api.post(this.$app, api.PAYMENTS +'bulk', data);
        data.payment.id = r.payment_id;
        data.payment.transaction_id = r.transaction_id;
        // commit('setPayment', data.payment);

        return r;
      },

      async payInvoicePAXLAN({ getters, commit, dispatch }, data = {}) {

        let r = await api.post(this.$app, api.PAYMENTS + 'bulk-PAX', data);
        data.payment.id = r.payment_id;
        data.payment.transaction_id = r.transaction_id;
        // commit('setPayment', data.payment);

        return r;
      },

      async generateInvoice({getters, commit, dispatch}, data = {}){
        const { promotions_to_remove = [] } = data;
        if(!getters.lease.id || getters.lease.status === 0) {
          return;
        }

        let lease = {
          billed_months: getters.billed_months || 0,
          payment_cycle: getters.lease.payment_cycle,
          tax_exempt: getters.getTaxExempt,
          tax_exempt_description: getters.getTaxExempt ? getters.getTaxExemptDescription : null,
          promotions_to_remove
        };
        
        if (data.isNational) {
          lease.national_accounts = true;
          lease.contact_id =  getters?.contact?.id;
        }

        let r = await api.post(this.$app, api.LEASES + getters.lease.id + '/generate-move-in-invoice', lease);
        commit('setInvoices', r.invoices);

        commit('paymentsStore/updateInvoices', {
          invoices: r.invoices
        }, { root: true });
      },

      async getEstimate({getters, commit, dispatch}, data = {}){
        let { generateInvoices = false } = data;
        let lease = {
          billed_months: getters.billed_months,
          payment_cycle: getters.lease.payment_cycle,
          tax_exempt: getters.getTaxExempt,
          tax_exempt_description: getters.getTaxExempt ? getters.getTaxExemptDescription : null,
          dryrun : true,
          national_accounts : true,
          contact_id :  getters?.contact?.id,
          billing_cycle_id :getters.configuration_lease.billing_cycle_id
        };
        if(data.contactNA){
          lease.contact_id = getters?.contact?.id || data?.contactNA;
        }

        if(!generateInvoices && !(getters.lease.status === 2)){  
          // if its not pending, get default charges
          if(!getters.lease.id || getters.lease.status === 0) {
            let error = await dispatch('getInitialCharges', data);
            return error;
          }
          else if (getters.configuration_lease.id && getters.configuration_unit.id) {
            await dispatch('getInitialCharges', data);
            return;
          }
        }

        commit('changeProcessPaymentStatus', true)
        let r = await api.post(this.$app, api.LEASES + getters.lease.id + '/generate-move-in-invoice', lease);
        if(data.config){
          commit('setConfigurationInvoice', r.invoices);
        } else {
          commit('setInvoices', r.invoices);

          let payments = [];
          for(let i = 0; i < r.invoices.length; i++){
            if(r.invoices[i].Payments && r.invoices[i].Payments.length){
              for(let j = 0; j < r.invoices[i].Payments.length; j++){
                let payment = payments.find(p => p.id == r.invoices[i].Payments[j].Payment.id);
                if(!payment){
                  payments.push(r.invoices[i].Payments[j].Payment);
                }
              }
            }
          }

          commit('setPayments', payments);
        }

        commit('paymentsStore/createLeaseStructure', {
          invoices: r.invoices,
          lease: getters.lease,
          unit: getters.unit,
          tax_exempt: getters.getTaxExempt || false,
          tax_exempt_description: getters.getTaxExempt ? getters.getTaxExemptDescription : null,
          updateInvoice: data.updateInvoice || false
        }, { root: true });
        commit('changeProcessPaymentStatus', false)
      },

      async getInitialCharges({getters, commit, dispatch}, data){

        let promotions = [];
        if( getters.configuration_lease.promotions){
          promotions = getters.configuration_lease.promotions.filter(
            (p) => p.Promotion.id
          );
        }

        promotions = promotions.map((p) => {
          return {
            promotion_id: p.Promotion.id,
          };
        });

        let products = getters.services.filter(s=> s.Product.default_type === 'product' || s.Product.default_type === 'late').map(s => {
          return {
            product_id: s.Product.id,
            qty: s.qty
          }
        })

        let start_date = getters.configuration_lease.start_date ? moment(getters.configuration_lease.start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        let params = {
          start_date: start_date,
          end_date: getters.configuration_lease.end_date,
          discount_id: getters.configuration_lease.discount_id,
          auto_pay_after_billing_date: getters.configuration_lease.auto_pay_after_billing_date || 0,
          payment_cycle: getters.configuration_lease.payment_cycle,
          BillingCycle: getters.configuration_lease.BillingCycle,
          billing_cycle_id: getters.configuration_lease.billing_cycle_id,
          promotions: promotions,
          bill_day: parseInt(getters.configuration_lease.bill_day),
          rent: getters.configuration_lease.rent,
          security_deposit: getters.configuration_lease.security_deposit,
          coupons: [],
          insurance_id: getters.configuration_lease.insurance_id || getters.insurance.insurance_id,
          table_rate_coverage_amount: getters.configuration_lease.table_rate_coverage_amount,
          billed_months: getters.billed_months,
          products: getters.configuration_lease.products || products,
          reservation_id: getters.reservation.id,
          is_transfer: data.is_transfer,
          transfer_lease_id: data.transfer_lease_id,
          edit_lease: getters.configuration_lease.id ? true: false,
          id: getters.configuration_lease.id,
          send_invoice: getters.configuration_lease.send_invoice,
          invoice_send_day: getters.configuration_lease.invoice_send_day,
          contact_id: getters?.contact?.id,
          tax_exempt: getters.getTaxExempt,
          tax_exempt_description: getters.getTaxExemptDescription,
          is_free_insurance: getters.configuration_lease.is_free_insurance
        }
        
        if (data.isNational) {
          params.national_accounts = true; 
          params.contact_id = data.contactNA; 
        }

        const unit_id = getters.configuration_unit.id ? getters.configuration_unit.id : getters.unit.id
        if(!unit_id) return;

        try {
          let r = await api.post(this.$app, api.UNITS + unit_id + '/configure', params);
          
          commit('setConfigurationInvoice', r.invoice);
          commit('paymentsStore/createLeaseStructure', {
            invoices: r.invoice,
            lease: getters.configuration_lease,
            unit: getters.configuration_unit,
            tax_exempt: getters.getTaxExempt || false,
            tax_exempt_description: getters.getTaxExempt ? getters.getTaxExemptDescription : null,
            updateInvoice: true
          }, { root: true });

        } catch(err){
          console.log("================================================================ error cacjh");
          console.log("err", err)
          dispatch('globalNotificationsStore/setGlobalNotification', {
            description: err 
          }, { root: true });

          return err;
        }


      },

      async deleteLease({getters, commit, dispatch}, lease){
        if(!lease.id) return;
        await api.delete(this.$app, api.LEASES, lease.id);
        commit('resetLease');
      },

      async deletePending({getters, commit, dispatch}){
        if(!getters.lease.id) return;

        try{
          await api.delete(this.$app, api.LEASES, getters.lease.id);
        } catch(err){
          throw err;
        }

        commit('resetLease');
        // commit('resetContact');
      },

      async getTaxRate({getters, commit}, data){
        let r = await api.get(this.$app, api.SETTINGS_BILLING, { property_id: getters.property.id } );
        commit('setTaxRate', r.settings.taxRate);
      },


      async loadChecklistItems({getters, commit, dispatch}, data) {
        let r = await api.get(this.$app, api.LEASES + getters.lease.id  + '/checklist');
        commit('setChecklistItems', r.items);
      },

      async generateDocument({getters, commit, dispatch}, index) {

        if(!getters.items[index].document_id) return;
        if(getters.items[index].upload_id) return;

        let response = await api.get(this.$app, api.LEASES + getters.items[index].lease_id + '/documents/' + getters.items[index].document_id + '/generate', { checklist_id: getters.items[index].id });



        commit('setChecklistItemStatus', {
          index,
          status: 'loading'
        });


      },

      async loadDocuments({getters, commit}, data) {
        for(let i = 0; i < getters.items.length; i++){
          if(!getters.items[i].document_id) continue;
          if(getters.items[i].upload_id) continue;
          let response  = await api.get(this.$app, api.LEASES + getters.lease.id + '/documents/' + getters.items[i].document_id + '/generate', { checklist_id: getters.items[i].id });

          //getters.items[i].status = 'loading';
        }
      },
      async regenerateItem({getters, commit, dispatch}, data) {
        let index = getters.items.findIndex(item => item.id === data.id);

        await api.delete(this.$app, api.LEASES + getters.items[index].lease_id + '/checklist-item/' + getters.items[index].id + '/upload');
        commit('resetChecklistItemUpload', {
          index
        });
        await dispatch("generateDocument", index);
      },

      async saveChecklistItemCompleted({getters, commit, dispatch}, data){

        await api.put(this.$app, api.LEASES + getters.lease.id + '/checklist-item/' + data.item.id, {
          completed: data.completed,
          upload_id: data.item.upload_id || null,
        });

        commit('updateChecklistItem', {
          checklist_id: data.item.id,
          completed: data.completed,
          upload_id: data.item.upload_id,
          Upload: data.item.Upload ? data.item.Upload: null
        })
      },
      setConfigurationLease({ commit}, payload){
        commit('setConfigurationLease', payload);
      },
      async findUnitLastClosedLease({getters, commit}, data) {
        let { unit_id } = data;
        let r = await api.get(this, api.UNITS + unit_id + '/lease',{fetch_details: false, fetch_last_closed_lease: true});
        commit('setLastClosedLease', r.lease);
      },
      async getContactLatestData({getters, commit}, data){
      // THE CONTACT OBJECT IN STORE IS NOT STAYING CONSISTENT THROUGHOUT THE FLOW IMPORTANT KEYS SUCH AS LEAD,LEASES ARE BEING REMOVED IN LEASE COMPONENTS WHICH SHOULDN'T BE DONE.?
      // ADDING THIS GETTER TO HAVE A CONSISTENT CONTACT LATEST OBJECT DO NOT CHANGE OR MODIFY "latestContactData" AS IT WILL CAUSE CATASTROPHIC ISSUES.
        console.log("getContactLatestData", data?.contact_id)
        if(!!data?.contact_id){
          let response = await api.get(this.$app, api.CONTACTS +  data.contact_id);
          if (response?.contact?.encrypted_driver_license) {
            let decrypt_response = await api.post(this.$app, api.CONTACTS + data.contact_id + '/decrypt-information', {driver_license: response?.contact?.encrypted_driver_license});
            response.contact = {...response.contact, driver_license: decrypt_response?.driver_license}
          }
          commit('setContactLatestData', response.contact);
        }
      },
      actionFollowUpStatus({commit},data){
        commit('setFollowUpStatus',data)
      },
    }
  }
}


export default OnBoarding;
