import api from '../../assets/api.js';

const formulatePropertiesInfo = (property) => {
    if(!property) return '';

    let propertyInformation = '';
    if(property.number) propertyInformation += property.number;
    if(property.Address && property.Address.city) propertyInformation += ` - ${property.Address.city}`;
    if(property.Address && property.Address.address) propertyInformation += ` - ${property.Address.address}`;

    return propertyInformation;
}

class Settings {
    constructor() {
		this.namespaced = true;

        this.state = {
            properties: null,
            unitFloors: null,
            unitMixes: null,

            promotions: {

            },
            ptod:{},
            fiscalYearMonth: "",
            allowMoveInEmailRequired: false,
            allowMoveInPhoneRequired: false,
        }

        this.getters = {
            properties: state => state.properties,
            unitFloors: state => state.unitFloors,
            unitMixes: state => state.unitMixes,
            ptod: state => state.ptod,
            fiscalYearMonth: state => state.fiscalYearMonth,
            allowMoveInEmailRequired: state => state.allowMoveInEmailRequired,
            allowMoveInPhoneRequired: state => state.allowMoveInPhoneRequired
        }

        this.mutations = {
            setProperties: (state, payload) => {
                state.properties = payload.properties.map((p) => {
                    return {
                        id: p.id,
                        name: formulatePropertiesInfo(p)
                    };
                });
            },

            setFloors: (state, payload) => {
                state.unitFloors = payload.floors;
            },

            setUnitMixCategories: (state, payload) => {
                state.unitMixes = payload.unitMixCategories.map(x=> ({
                    id: x.id,
                    name: `${x.width} x ${x.length} x ${x.height} - ${x.category}`
                }));
            },
            setPtod: (state, payload) => {
                state.ptod = payload;
            },
            setFiscalYearMonth: (state, fiscalYearMonth) => {
                state.fiscalYearMonth = fiscalYearMonth;
            },
            setEmailPhoneRequiredSettings: (state, settings) => {
                if (!Array.isArray(settings) || settings.length === 0){
                    state.allowMoveInEmailRequired = false;
                    state.allowMoveInPhoneRequired = false;
                } else{
                    const getSettingValue = (settingName) => {
                        const setting = settings.find(item => item.name === settingName);
                        return setting ? parseInt(setting.value) === 1 : false;
                    };
                    state.allowMoveInEmailRequired = getSettingValue("allowMoveInEmailRequired");
                    state.allowMoveInPhoneRequired = getSettingValue("allowMoveInPhoneRequired");
                }             
            }            
        }

        this.actions = {
            async fetchProperties({ commit, state }) {
                if(state.properties == null) {
                    const propertiesRes = await api.get(this.$app, api.PROPERTIES + "?all=true");
                    commit('setProperties', { properties: propertiesRes.properties });
                }
            },

            async fetchFloors({ commit, state }) {
                if(state.unitFloors == null) {
                    const floorsRes = await api.get(this.$app, api.UNITS + "floors");
                    commit('setFloors', { floors: floorsRes.floors } );
                }
            },

            async fetchCategories({ commit, state }) {
                if(state.unitMixes == null) {
                    let unitMixRes = await api.get(this.$app, api.SPACE_MIX);
                    commit('setUnitMixCategories', { unitMixCategories: unitMixRes.space_mix });
                }
            },

            // Not used currently
            async fetchPromotionTypes() {
                let r = await api.get(this.$app, api.PROMOTIONS + "types");
                this.promotion_types = r.promotion_types;
            },

            // get paid to date settings
            async fetchPaidToDateSettings({ commit, state }) {
                let r = await api.get(this.$app, api.SETTINGS + "?category=ptod");
                commit('setPtod', r.settings);
            },
            async fetchFiscalYearMonth({ commit }) {
                try {
                    let data = await api.get(this.$app, api.SETTINGS_BILLING);
                   commit('setFiscalYearMonth', data?.settings?.fiscalYearMonth);
                }
                catch(err) {
                    throw err;
                }
            },
            setFiscalYearMonth({ commit }, fiscalYearMonth) {
                commit('setFiscalYearMonth', fiscalYearMonth);
            },
            async getEmailPhoneRequiredSettings({ commit }){
                let data = await api.get(this.$app, api.SETTINGS + '?category=moveIn');
                commit('setEmailPhoneRequiredSettings', data.settings || []);
            }
        }
    }
}

export default Settings;
