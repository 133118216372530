class CloseOfDay {
	constructor(){
		this.namespaced = true;
		this.state = {
			isCorporateCODEnabled: false,
			isCorporateSPTEnabled: false,
		};
		this.getters = {
			isCorporateCODEnabled: state => state.isCorporateCODEnabled,
			isCorporateSPTEnabled: state => state.isCorporateSPTEnabled
		};
		this.mutations = {
			updateCorporateCOD(state, payload){
				state.isCorporateCODEnabled = payload;
			},
			updateCorporateSPT(state, payload){
				state.isCorporateSPTEnabled = payload;
			},
		}
	}
}

export default CloseOfDay;