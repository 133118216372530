import api from "../../../assets/api.js";

const mapFeatures = (amenities) => {
  const allItems = [];
  for (const amenity of amenities) {
    const temp = {
      ...amenity,
      options: amenity.property_options
      ? JSON.parse(amenity.property_options)
      : JSON.parse(amenity.options),
      feature:
        amenity.property_amenity_name ?? amenity.master_amenity_name,
      default:
        amenity.property_default_value ?? amenity.master_default_value,
      category_name:
        amenity.property_category_name ?? amenity.master_category_name,
      checked: 
        amenity.property_id ? true : false,
    };
    allItems.push(temp)
  }
  return allItems;
}

export default {

  async fetchAllCategoriesFeatures({ commit }, data) {
    try {
      const res = await api.get(
        this.$app,
        api.PROPERTY_AMENITIES + `${data.property_id}/space-type/${data.unit_type_id}/amenities?type=list&allCatagories=true`
      );
      const allItems = mapFeatures(res.amenities);
      commit("setAllCategoriesFeaturesList", allItems);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchAllFeatures({ commit }, data) {
    try {
      const res = await api.get(
        this.$app,
        api.PROPERTY_AMENITIES + `${data.property_id}/space-type/${data.unit_type_id}/amenities?type=add`
      );
      const allItems = [];
      for (const item of res.amenities) {
        let allFeatures = mapFeatures(item.amenities);
        allItems.push({ ...item, amenities: allFeatures });
      }
      commit("setAllFeaturesList", allItems);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchFeatures({ commit }, data) {
    try {
      commit("setAddedFeaturesListLoading");
      const res = await api.get(
        this.$app,
        api.PROPERTY_AMENITIES + `${data.property_id}/space-type/${data.unit_type_id}/amenities?type=list`
      );
      const allItems = mapFeatures(res.amenities);
      commit("setAddedFeaturesList", allItems);
      commit("setAddedFeaturesListLoaded");
    } catch (e) {
      console.log(e);
    }
  },
  async fetchSpaceTypes({ commit ,dispatch}) {
    
    let space_types = JSON.parse( localStorage.getItem('hb_space_types')  || "{}");

    if(Object.keys(space_types).length == 0){
      await api.get(this.$app, api.SPACE_TYPES)
      .then(async(response) => {
        space_types = JSON.parse(JSON.stringify(response));
        localStorage.setItem('hb_space_types', JSON.stringify(response))
      })
    }

    commit("setUnitTypes", space_types); 
    await dispatch('filterStore/getUnitTypes',space_types, {root: true});
  },
  async saveFeatures({ commit }, { data, spaceType, isDelete = false, sort = false, unit_type_id }) {
    try {
      const category_names = [];
      let index = 1;
      data.forEach((item) => {
        if (item.checked) {
          item.property_sort_order = index++;
        }
        if (!category_names.includes(item.category_name)) {
          category_names.push(item.category_name);
        }
      });
      const amenities = category_names.map(category => ({
        category,
        amenities: data.filter(item => category === item.category_name)
      }));
      if (sort) {
        await api.put(this.$app, api.UPDATE_SORT_ORDER, amenities);
        commit("setAddedFeaturesList", data);
      } else {
        await api.put(this.$app, api.ADD_AMENITIES, { amenities, isDelete, spaceType, unit_type_id });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
