
import api from '../../assets/api.js';
import Vue from 'vue';
import { EventBus } from '../../EventBus.js';
import { parsePhoneNumber } from 'libphonenumber-js';

const types = {
  SET_LOADED: 'SET_LOADED',
  SET_ROLES: 'SET_ROLES',
  SET_ROLE_PERMISSIONS: 'SET_ROLE_PERMISSIONS',
  SET_CID: 'SET_CID',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_COMPANY_SETTINGS :'SET_COMPANY_SETTINGS',
  SET_COMPANY_DATA: 'SET_COMPANY_DATA',
  SET_GLOBAL_SETTINGS: 'SET_GLOBAL_SETTINGS',
  ACTIVE_ROUTE: 'ACTIVE_ROUTE',
  AUTHENTICATE: 'AUTHENTICATE',
  UPDATE_AUTH: 'UPDATE_AUTH',
  REMOVE_DATA: 'REMOVE_DATA',
  SET_GDS_APPS: 'SET_GDS_APPS',
  ADD_GDS_APP_EXTENSION: 'ADD_GDS_APP_EXTENSION',
  SET_CMS_APP_ID: 'SET_CMS_APP_ID',
  SET_GLOSSARY_APP_ID: 'SET_GLOSSARY_APP_ID',
  SET_PLATFORM_APP_TOKEN: 'SET_PLATFORM_APP_TOKEN',
  SET_PLATFORM_APPS_LOADED: 'SET_PLATFORM_APPS_LOADED',
  RESET_PLATFORM_APPS_LOADED: 'RESET_PLATFORM_APPS_LOADED',
  PLATFORM_APPLICATIONS: 'PLATFORM_APPLICATIONS',
  REMOVE_GDS_APPS_AND_EXTENSIONS: 'REMOVE_GDS_APPS_AND_EXTENSIONS',
  RESET_EXTENSION_POINTS: 'RESET_EXTENSION_POINTS',
  SET_HB_USER: 'SET_HB_USER',
  SET_VERITEC_APP_ID: 'SET_VERITEC_APP_ID',
  SET_PRIMARY_ROLE_ID: 'SET_PRIMARY_ROLE_ID',
  SET_INTER_PROPERTY_ROLE_ID: 'SET_INTER_PROPERTY_ROLE_ID',
  SET_PROPERTY_PERMISSIONS: 'SET_PROPERTY_PERMISSIONS',
  CHARM_AGENT_AVAILABILITY: 'CHARM_AGENT_AVAILABILITY',
  SET_NECTAR_APP_ENDPOINT_RESPONSE: 'SET_NECTAR_APP_ENDPOINT_RESPONSE',
  SET_HB_SUBSCRIBED_APPLICATIONS: 'SET_HB_SUBSCRIBED_APPLICATIONS',
  SET_DEVELOPER_PORTAL_APP_ID: 'SET_DEVELOPER_PORTAL_APP_ID',
  SET_NECTAR_APPS_WITH_NOTIFICATIONS: 'SET_NECTAR_APPS_WITH_NOTIFICATIONS',
  UPDATE_NECTAR_APP_NOTIFICATION_STATUS: 'UPDATE_NECTAR_APP_NOTIFICATION_STATUS',
  SET_BUSINESS_INTELLIGENCE_SETTINGS: 'SET_BUSINESS_INTELLIGENCE_SETTINGS',
}

class Authentication {
  constructor() {
    this.namespaced = true;
    this.state = {
      cid: null,
      contact: {
        role: null,
      },
      settings : {},
      primary_role_id: '',
      inter_property_role_id: '',
      activeRoute: '',
      is_authenticated: false,
      charm_agent_availability:false,
      company: {
        name: null,
        logo: null,
        platform_integration_enabled: 0,
      },
      company_phone_code: '',
      platformApplications: false,
      global_settings: {
        zoom_url: null
    },
      auth_token: '',
      activeNav: '',
      roles: [],
      rolePermissions: [],
      permission_properties: [],
      site_loaded: false,
      apps: [],
      platform_apps_loaded: false,
      extension_points: {},
      localStorage: window.localStorage,
      cms_app_id: '',
      glossary_app_id:'',
      platform_app_token: '',
      isHBUser: true,
      veritec_app_id: '',
      nectar_app_response: {},
      hb_subscribed_applications: [],
      developer_portal_app_id: '',
      nectar_apps_with_notification: [],
      inter_property_operations_name: 'inter-property-operations',
      business_intelligence_settings: {
        toggleBusinesIntelligence: false,
        businesIntelligenceLink: null
      },
    };
    this.getters = {
      getCid: state => console.log("company", state.cid) || state.cid,
      getUserType: state => state.contact.roles,
      getUserData: state => state.contact,
      getSubdomain: state => state.company.subdomain,
      getCompanyName: state => state.company.name,
      getCompany: state => state.company,
      getCompanyLogo: state => state.company.logo,
      getCompanyPhoneCode: state => {
        const contact_data = JSON.parse( localStorage.getItem('contact_data')  || "{}");
        const number = (contact_data?.contact?.Company?.phone)?.toString();
        if(number){
          const phone_details = parsePhoneNumber('+' + (number || ''));
          state.company_phone_code = phone_details.countryCallingCode;
        }
        return state.company_phone_code ? state.company_phone_code : '1';
      },
      getCompanyNames: state => {
        return state.contact.Companies ? state.contact.Companies.map(company => company.name) : []
      },
      getSettings : state => state.settings,
      getZoomURL: state => state.global_settings.zoom_url,
      getPlatformIntegrationEnabled: state => state.company.platform_integration_enabled,
      getPlatformAppsLoaded: state => state.platform_apps_loaded,
      hasPlatformApps: state => state.platformApplications,
      isAdmin: state => state.is_authenticated && state.contact.roles?.includes('admin'),
      isSuperAdmin: state => state.is_authenticated && state.contact.roles?.includes('admin') && state.contact.id === 'DeMjRz0jrZ',
      isTenant: state => state.is_authenticated && state.contact.roles?.includes('tenant'),
      getToken: state => state.auth_token,
      getAuthHeader: state => localStorage.getItem('auth_token'),
      checkAuth: state => state.is_authenticated,
      isLoaded: state => state.site_loaded,
      hasPermission: state => { 
        if(state.hasOwnProperty('roles') && Array.isArray(state.roles) && state.roles.length > 0){
          state.roles.filter(function(role){
            var permissions = Object.keys(state.activeRoute.meta.permissions);
            if(permissions.indexOf(role.name) > -1){
                var permitted = state.activeRoute.meta.permissions[role.name];
                return role.rolesPermissions.filter(function(r){
                    if(permitted.indexOf(r.name) > -1 ) {
                        return r.state;
                    }
                    return false
                }).length;
            }
            return false;
          }).length; 
        }else{
          return ;
        }
      },
      rolePermission: (state) => (param = '') => {
        let check = state.rolePermissions.find(role => role.label === param);
        return check ? true : false;
      },
      charmAgentAvailability:(state) => (param = '')=>{
        let check = state.charm_agent_availability
        return check.available ? true : false;
      },
      checkPermissionProperties: (state) => (params = {}) => {
        let permissionProperties = state.permission_properties.find(p => p.label === params.label);
        return permissionProperties?.properties?.length ? params.properties.every(p => permissionProperties.properties.includes(p)) : false;
      },
      getActiveRoute: state => state.activeRoute,
      getExtensionPoints: (state) => {
        return state.extension_points
      },
      getCmsAppId: state => state.cms_app_id,
      getGlossaryAppId: state => state.glossary_app_id,
      getPlatformAppToken: state => state.platform_app_token,
      getApplications: (state) => {
        return state.apps
      },
      isHBUser: state => state.isHBUser,
      getVeritecAppId: state => state.veritec_app_id,
      getPrimaryRoleId: state => state.primary_role_id,
      getInterPropertyRoleId: state => state.inter_property_role_id,
      getApplicationEndpointResponse: state => state.nectar_app_response,
      getHbSubscribedApplications: state => state.hb_subscribed_applications,
      getDeveloperPortalAppId: state => state.developer_portal_app_id,
      getNectarAppsWithNotification: state => state.nectar_apps_with_notification,
      getInterPropertyName: state => state.inter_property_operations_name,
      getBusinessIntelligenceSettings: state => state.business_intelligence_settings,
      isBusinessIntelligenceEnabled: state => {
        return (state.business_intelligence_settings.toggleBusinesIntelligence) ? true : false;
      },
      getBusinessIntelligenceLink: state => {
        return state.business_intelligence_settings.businesIntelligenceLink;
      }
    };
    this.actions = {
      setCharmAgentAvailability({commit, dispatch}, payload){ 
        commit(types.CHARM_AGENT_AVAILABILITY, {available:payload});
      },
      authenticate({commit, dispatch}, payload) {
        // localStorage.setItem('auth_token', payload.auth_token);
        // commit(types.AUTHENTICATE, payload.is_authenticated);
        dispatch('setAuthenticated', {...payload, cid:localStorage.getItem("company")});
        commit(types.UPDATE_AUTH, payload.auth_token);
        commit(types.UPDATE_AUTH, payload.auth_token);
      },
      setLoaded({commit}) {
        commit(types.SET_LOADED, true);
      },
      setRoles({commit}, payload) {
        commit(types.SET_ROLES, payload.roles);
      },
      setRolePermissions({commit}, payload) {
        commit(types.SET_ROLE_PERMISSIONS, payload);
      },
      setPropertyPermissions({commit}, payload) {
        commit(types.SET_PROPERTY_PERMISSIONS, payload);
      },
      setUserData({commit}, payload) {
        commit(types.SET_USER_DATA, payload);
        localStorage.setItem('contact_roles',JSON.stringify(payload.contact.roles));
      },
      setCompanySettings({commit}, payload) {
        commit(types.SET_COMPANY_SETTINGS, payload);
        localStorage.setItem('contact_data',JSON.stringify(payload));
      },
      setPlatformAppToken({commit}, payload) {
        commit(types.SET_PLATFORM_APP_TOKEN, payload);
      },
      setCid({commit}, payload) {
        commit(types.SET_CID, payload);
      },
      activeRoute({commit}, payload) {
        commit(types.ACTIVE_ROUTE, payload);
      },
      setAuthenticated({commit}, payload) {
        localStorage.setItem('auth_token', payload.auth_token);
        localStorage.setItem('company',payload.cid);
        commit(types.AUTHENTICATE, payload.is_authenticated);
        commit(types.SET_CID, {cid:payload.cid});
      },
      removeAppsAndExtensions({state, commit}) {
        commit(types.REMOVE_GDS_APPS_AND_EXTENSIONS);
        commit(types.RESET_PLATFORM_APPS_LOADED);
      },
      async logout({commit, dispatch, getters, rootState}, payload) {
        let cid = getters.getCompany.id;
        EventBus.$emit('destroyWebsockets');
        sessionStorage.removeItem('AzureLogin')
        if(getters.rolePermission('mini_charm')) {        
        if(cid){            
          let data = {
            agent_id: getters?.getUserData?.id,
            // availability: 'unavailable',
            logged_in: false,
            from: 'logout',
            property_ids: [],
            facilities: {
            available: [],
            // unavailable: rootState?.propertiesStore?.properties?.filter(p => p.gds_id)?.map(p => p.gds_id)            
            },
            storedBrowserID : localStorage.getItem('browserID')
          }
          dispatch('charmSubscriptionStore/setAvailableAgent', data, {root: true});
        }
      }
        
        await dispatch('propertiesStore/clearData',null, {root: true});
        await dispatch('clearData');
        await dispatch("removeAppsAndExtensions");
      },
      async updateAuth({commit}, payload) {
        var jwt = localStorage.getItem('auth_token');
        var company_id = localStorage.getItem('company');

        if (jwt && company_id) {
          commit(types.UPDATE_AUTH, jwt);
          commit(types.AUTHENTICATE, true);
          commit(types.SET_CID,{cid:company_id})
        } else {
          commit(types.AUTHENTICATE, false);
        }
      },
      async getLoggedInUser({commit, dispatch, getters, rootState}, reload) {
        
        let userDetails = JSON.parse( localStorage.getItem('hb_user_details')  || "{}");
        let contactData = JSON.parse( localStorage.getItem('contact_data')  || "{}");
        

        if(Object.keys(userDetails).length == 0){
          await api.get(this.$app, api.LOGGED_IN_USER)

          .then(async(response) => {
            userDetails = JSON.parse(JSON.stringify(response));
            localStorage.setItem('hb_user_details', JSON.stringify(response))
          })
          .catch(async err => {
            await dispatch('statusStore/setStatus', {
              status: false,
              message: "You are not authorized to access that location"
            });
          });

        }

        if (userDetails) {
          localStorage.setItem('company',userDetails.cid);
          commit(types.SET_USER_DATA, {
            contact: userDetails.contact
          });
          dispatch('setRoleIds');
          commit(types.SET_CID, {
            cid: userDetails.cid,
          });
          let activeData = userDetails.active;
          commit(types.SET_COMPANY_DATA, {
            company: activeData
          });

          await dispatch('featureAndAmenitiesStore/fetchSpaceTypes',{all: true}, {root: true});

          await dispatch('setRolePermissions',userDetails.permissions);


          await dispatch('propertiesStore/setProperties', {all: true}, {root: true});
          
          let primary_role = userDetails.RolesProperties?.filter(r => r.role_id === getters.getPrimaryRoleId)
          let properties = primary_role[0]?.Properties || []
          await dispatch('propertiesStore/setFiltered', properties, {root: true});
          await dispatch('propertiesStore/fetchPropertyGroupTypes',{}, {root: true}); 
          await dispatch('propertiesStore/fetchPropertyGroups',{},{root: true});     
          
          if(userDetails.property_group_id) {   
            dispatch('propertiesStore/populateSelectedGroup', userDetails.property_group_id, {root: true});
          }
          
          // await dispatch('propertiesStore/setPrimaryRoleProperties', {properties,userId: userDetails?.contact?.id}, {root: true});
          await dispatch('propertiesStore/setPrimaryRoleProperties', {userId: userDetails?.contact?.id}, {root: true});
          await dispatch('propertiesStore/setBillingSettings', {all: true}, {root: true});
          let hbPermissionProperties = JSON.parse(localStorage.getItem('hb_permission_properties') || "[]");
          if(!hbPermissionProperties.length) {
            await dispatch('setPropertyPermissions', userDetails?.permission_properties);
            localStorage.setItem('hb_permission_properties', JSON.stringify(userDetails?.permission_properties));
          } else {
            await dispatch('setPropertyPermissions', hbPermissionProperties);
          }
          await dispatch('propertiesStore/setNonHBProperties',{all: true}, {root: true});
          
          let platformIntegration = activeData?.platform_integration_enabled;
          if (platformIntegration) {
            dispatch('setInstalledApps');
          }

          if(!getters.isLoaded){
            await dispatch("onboardingTabsStore/setOnboardingData",{all: true}, {root: true}); 
          }
          commit(types.SET_LOADED, true);

          // BCT: CHR-136 Get properties with Mini_Charm_subscribed status
          dispatch('charmSubscriptionStore/setUserDetails', {userId: userDetails?.contact?.id, permissions: userDetails?.permissions}, {root: true});
          dispatch('charmSubscriptionStore/getProperties', {userId: userDetails?.contact?.id, permissions: userDetails?.permissions,primaryRoleProperties: properties}, {root: true});
        }
        if(contactData){
          await dispatch('setCompanySettings', contactData);
        }
      },
      async updateNectarNotifications({commit}, payload) {
        commit(types.UPDATE_NECTAR_APP_NOTIFICATION_STATUS, payload)
      },
      async setNectarApplications({commit, dispatch, getters}) {
        let application_id = process.env.VUE_APP_NECTAR_APP_ID;
        let base_url = process.env.VUE_APP_GDS_BASE_URL;
        let NECTAR_APP_ENDPOINT = base_url + '/v3/applications/' + application_id + '/v1/owners/' + getters.getCompany.gds_owner_id + '/applications/';
        let apps = {}
        let headers = {
          'X-storageapi-key': process.env.VUE_APP_GDS_API_KEY,
          'X-storageapi-date': String(Date.now()),
          'X-nectarapi-version': 'V2'
        }
        try {
          let response = await Vue.http.get(
            NECTAR_APP_ENDPOINT, { headers: headers });                  

          let res = response.body.applicationData[application_id][0].applicationData;
          apps = Object.assign({}, res?.availableApplications ?? {}, res?.subscribedApplications ?? {})

          commit(types.SET_NECTAR_APP_ENDPOINT_RESPONSE, res)
          if (getters.getDeveloperPortalAppId) {
            await dispatch('setHbSubscribedApplications');
            await dispatch('setNectarAppNotifications');
          }
        }
        catch(e) {
          console.log("Error occurred while setting nectar application response", e)
        }
        return apps;
      },

      async setNectarAppNotifications({commit, getters}) {
        commit(types.SET_NECTAR_APPS_WITH_NOTIFICATIONS, [])
        let hbSubscribedApps = getters.getHbSubscribedApplications;
        let installedApps = getters.getApplicationEndpointResponse?.installed_apps
        
        let appsWithNotifications = []

        hbSubscribedApps?.forEach((application) => {
          if (application.app_urn) {
            let appDetails = installedApps?.find((installedApp) => installedApp.app_id === application.app_urn);
            if (
              appDetails && 
              appDetails.isSubscribable && 
              appDetails.hasPropertySubscription && 
              appDetails.notify
            ) appsWithNotifications?.push(appDetails);
          }
        });
        if (appsWithNotifications.length) {
          commit(types.SET_NECTAR_APPS_WITH_NOTIFICATIONS, appsWithNotifications)
        }
      },

      async setInstalledApps({commit, dispatch, getters}, payload) {
        if (Object.keys(getters.getExtensionPoints).length) return;
        if (!getters.getPlatformIntegrationEnabled) return;
        let headers = {
          'X-storageapi-key': process.env.VUE_APP_GDS_API_KEY,
          'X-storageapi-date': String(Date.now()),
        }
        try {
          let apps = await dispatch('setNectarApplications');
          let keys = Object.keys(apps);
          let hasAppExtenstions = false
          let appList = {}
          let promises = [];
          for (let i = 0; i < keys.length; i++) {
            let app_id = keys[i];
            let appSlug = apps[app_id]?.slug
            if (appSlug) appList[appSlug] = app_id
            if (apps[app_id]?.slug == 'veritec-app') commit(types.SET_VERITEC_APP_ID, app_id)
            if (apps[app_id]?.slug == 'developer-portal') {
              await dispatch('setHbSubscribedApplications');
              await dispatch('setNectarAppNotifications');
              commit(types.SET_DEVELOPER_PORTAL_APP_ID, app_id)
            }
            if (!apps[app_id].bundle) continue;

            let script_id = 'gds_' + app_id;
            if (document.getElementById(script_id)) continue
            let bundle_url = apps[app_id].bundle;
            if (apps[app_id]?.slug == 'cms-app') commit(types.SET_CMS_APP_ID, app_id)
            if (apps[app_id]?.name?.en == "Glossary") commit(types.SET_GLOSSARY_APP_ID, app_id)
            dispatch('dashboardsStore/updateUpsellLoader', true, {root: true})
            if (apps[app_id].extensions) {
              hasAppExtenstions = true
              promises.push(Vue.http.get(bundle_url, { headers: headers }).then((response) => {
                let script = document.createElement('script');
                script.id = script_id;
                let script_content = document.createTextNode(response.data);
                script.appendChild(script_content);
                document.getElementsByTagName('HEAD')[0].appendChild(script);
                for (let j = 0; j < apps[app_id]?.extensions.length; j++) {
                  let ext_data = apps[app_id]?.extensions[j]                  
                  let extension = {
                    name: ext_data?.label?.en,
                    url: ext_data?.url,
                    tags: ext_data?.tags?.length ? ext_data?.tags : [],
                    terms: ext_data.terms ?? [],
                    component: ext_data?.component,
                    id: ext_data?.id,
                    icon: ext_data.icon ? ext_data.icon : apps[app_id]?.icon,
                    extensions: apps[app_id]?.extensions,
                    description: apps[app_id]?.description,
                    appId: app_id,
                    enableSearchField: !!ext_data?.config?.enableSearchField,
                    hasSideBar: !!ext_data?.config?.hasSideBar

                  }
                  let ext_type = apps[app_id]?.extensions[j]?.type
                  if (ext_type == "cms") commit(types.SET_CMS_APP_ID, app_id)
                  
                  commit(types.ADD_GDS_APP_EXTENSION, {
                    type: ext_type,
                    data: extension,
                    appId: app_id
                  });
                }
              }).catch(error => {
                console.log("Error occured while fetching app bundle", error);
              }));
            }
          }
          commit(types.SET_GDS_APPS, appList);
          commit(types.PLATFORM_APPLICATIONS, hasAppExtenstions)
          await Promise.allSettled(promises)
          commit(types.SET_PLATFORM_APPS_LOADED, {loaded: true});
        } catch (error) {
          commit(types.PLATFORM_APPLICATIONS, false)
          console.log(error);
        }
      },
      async getGlobalSettings({commit}, payload) {
        let res = await api.get(this.$app, api.GLOBAL_SETTINGS);
        if(res){
          commit(types.SET_GLOBAL_SETTINGS, res.global_settings);
        }



      },
    setRoleIds({commit, getters}) {
      let getInterPropertyName = getters.getInterPropertyName
      getters.getUserData?.Roles?.forEach(r => {
        let ippName = r.name?.toLowerCase().split(" ").join("-")
        if(getInterPropertyName === ippName) 
          commit(types.SET_INTER_PROPERTY_ROLE_ID, r.role_id);
        else 
          commit(types.SET_PRIMARY_ROLE_ID, r.role_id);
      })

      },
      clearData({commit},payload){
        commit(types.REMOVE_DATA);
      },
      async setHbSubscribedApplications({commit}) {
        let response = {}
        try {
          response = await api.get(this, api.APPLICATIONS);
        } catch(e) {
          console.log(e)
        }
        commit(types.SET_HB_SUBSCRIBED_APPLICATIONS, response?.application ?? [])
      },
      setBusinessIntelligenceSettings({ commit }, payload) {
        commit(types.SET_BUSINESS_INTELLIGENCE_SETTINGS, payload);
      }
    };
    this.mutations = {
      [types.SET_LOADED](state, isLoaded) {
        state.site_loaded = isLoaded;
      },
      [types.ACTIVE_ROUTE](state, payload) {
        state.activeRoute = payload;
      },
      [types.AUTHENTICATE](state, payload) {
        state.is_authenticated = payload;
      },
      [types.UPDATE_AUTH](state, payload) {
        state.auth_token = payload;
      },
      [types.SET_ROLES](state, payload) {
        state.roles = payload;
      },
      [types.SET_ROLE_PERMISSIONS](state, payload) {
        state.rolePermissions = payload;
      },
      [types.CHARM_AGENT_AVAILABILITY](state, payload) {
        state.charm_agent_availability = payload;
      },
      [types.SET_USER_DATA](state, payload) {
        state.contact = payload.contact ? payload.contact : payload;
      },
      [types.SET_COMPANY_SETTINGS](state, payload) {
        state.settings = payload.contact.Company.Settings;
      },
      [types.SET_CID](state, payload) {
        state.cid = payload.cid;
      },
      [types.SET_COMPANY_DATA](state, payload) {
        state.company = payload.company;
      },
      [types.SET_GDS_APPS](state, payload) {
        state.apps = payload;
      },
      [types.SET_PLATFORM_APPS_LOADED](state, payload) {
        state.platform_apps_loaded = payload.loaded;
      },
      [types.RESET_PLATFORM_APPS_LOADED](state) {
        state.platform_apps_loaded = false;
      },
      [types.PLATFORM_APPLICATIONS](state, payload) {
        state.platformApplications = payload
      },
      [types.RESET_EXTENSION_POINTS](state) {
        state.extension_points = {}
      },
      [types.ADD_GDS_APP_EXTENSION](state, payload) {
        if (!state.extension_points[payload.type]) {
          Vue.set(state.extension_points, payload.type, []);
        }
        state.extension_points[payload.type].unshift(payload.data);

      },
      [types.SET_CMS_APP_ID](state, payload) {
        state.cms_app_id = payload
      },
      [types.SET_GLOSSARY_APP_ID](state, payload) {
        state.glossary_app_id = payload
      },
      [types.SET_PLATFORM_APP_TOKEN](state, payload) {
        state.platform_app_token = payload
      },
      [types.SET_GLOBAL_SETTINGS](state, payload) {
        state.global_settings = payload;
      },
      [types.REMOVE_DATA](state, payload) {
        // Object.keys(state).forEach(key => {
        //   if(typeof state[key] == 'object')
        //     state[key] = {};
        //   else if (typeof state[key] == 'string')
        //     state[key] = "";
        //   else if(typeof state[key] == 'boolean')
        //     state[key] = true;
        // });

        state.cid = null;
        state.contact = {};
        state.company = {};
        state.roles = [];
        state.rolePermissions = [];
        state.site_loaded = false;




        let scripts = document.querySelectorAll(`[id^="gds_"]`)
        scripts?.forEach(script => {
            let head = document.getElementsByTagName('head')?.[0];
            head.removeChild(script);
        })

        localStorage.removeItem('auth_token');
        localStorage.removeItem('company');
        localStorage.removeItem('contact_roles');
        localStorage.removeItem('contact_data');
        localStorage.removeItem('hb_user_details');
        sessionStorage.removeItem('charm_subscribed_properties');
        localStorage.removeItem('charm_subscribed_properties');
        localStorage.removeItem('hb_properties');
        localStorage.removeItem('hb_permission_properties');
        localStorage.removeItem('hb_primary_role_props');
        localStorage.removeItem('non_hb_prop');
        localStorage.removeItem('hb_space_types');
        localStorage.removeItem('hb_property_groups');
        localStorage.removeItem('hb_property_group_types');
      },
      [types.REMOVE_GDS_APPS_AND_EXTENSIONS](state) {
        state.apps = [];
        state.extension_points = {};
      },
      [types.SET_HB_USER](state, payload) {
        state.isHBUser = payload
      },
      [types.SET_VERITEC_APP_ID](state, payload) {
        state.veritec_app_id = payload
      },
      [types.SET_DEVELOPER_PORTAL_APP_ID](state, payload) {
        state.developer_portal_app_id = payload
      },

      [types.SET_PRIMARY_ROLE_ID](state, payload) {
        state.primary_role_id = payload
      },
      [types.SET_INTER_PROPERTY_ROLE_ID](state, payload) {
        state.inter_property_role_id = payload
      },
      [types.SET_PROPERTY_PERMISSIONS](state, payload) {
        state.permission_properties = [...payload];
      },

      [types.SET_NECTAR_APP_ENDPOINT_RESPONSE](state, payload) {
        let availableApps = Object.keys(payload.availableApplications)?.map(app_id => {
          payload.availableApplications[app_id].app_id = app_id;
          return payload.availableApplications[app_id];
        });
        let subscribedApps = Object.keys(payload.subscribedApplications)?.map(app_id => {
          payload.subscribedApplications[app_id].app_id = app_id;
          return payload.subscribedApplications[app_id];
        });
        state.nectar_app_response = {
          available_apps: availableApps ?? [],
          installed_apps: subscribedApps ?? []
        };
      },

      [types.UPDATE_NECTAR_APP_NOTIFICATION_STATUS](state, payload) {
        state.nectar_apps_with_notification.forEach(app => {
          if(app.app_id === payload.app_id) app.notify = payload.notify;
        })
      },

      [types.SET_HB_SUBSCRIBED_APPLICATIONS](state, payload) {
        state.hb_subscribed_applications = payload
      },
      [types.SET_NECTAR_APPS_WITH_NOTIFICATIONS](state, payload) {
        state.nectar_apps_with_notification = payload
      },
      [types.SET_BUSINESS_INTELLIGENCE_SETTINGS](state, payload) {
        state.business_intelligence_settings = payload;
      },
    };
  }
}

export default Authentication;